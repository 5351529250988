import React from "react";

/** MUI */
import { alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    paper: {
        border: `2px solid ${alpha(theme.palette.primary.main, 0.125)} `,
        backdropFilter: "blur(24px)",
        borderRadius: 16,
        padding: theme.spacing(5, 4),
        background: "rgba(20,20,20,0.04)",
        width: "95vw",
        maxWidth: 600,
        display: "flex",
        flexDirection: "column",
        color: theme.palette.common.white,
        [theme.breakpoints.down('sm')]:{
            padding: theme.spacing(2, 0),
            margin: 0
        }
    },
    label: {
        color: theme.palette.common.white,
    },
    input: {
        "&, &:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.125)",
        },
        "&:before, &:hover": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.42)",
        },
    },
}));
const RequestACall = (props) => {
    const { show, onClose } = props;
    const classes = useStyles();

    const handleClose = () => onClose();

    return (
        <>
            <Dialog
                open={show}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="request-a-call-dialog"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle sx={{ textAlign: "center", mb: 3 }}>{"Request A Call"}</DialogTitle>
                <DialogContent>
                    <DialogContentText color={"inherit"}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet dictum eros. Mauris
                        accumsan velit at tellus porta suscipit. Pellentesque leo nunc, consequat eu convallis in,
                        dictum quis mauris. Nam aliquet non dolor eget pretium.
                    </DialogContentText>
                    <Stack direction="column" justifyContent="center" alignItems="stretch" spacing={2} sx={{ mb: 5 }}>
                        <TextField
                            id="filled-username"
                            label="Name"
                            variant="filled"
                            fullWidth
                            sx={{
                                mt: 5,
                                mb: 2,
                                "& .Mui-focused svg": { color: (theme) => theme.palette.secondary.main },
                            }}
                            color={"secondary"}
                            InputProps={{
                                classes: { root: classes.input },
                            }}
                            InputLabelProps={{
                                classes: { root: classes.label },
                            }}
                        />
                        <TextField
                            id="filled-phone"
                            label="Phone"
                            variant="filled"
                            fullWidth
                            sx={{
                                "& .Mui-focused svg": { color: (theme) => theme.palette.secondary.main },
                            }}
                            color={"secondary"}
                            InputProps={{
                                classes: { root: classes.input },
                            }}
                            InputLabelProps={{
                                classes: { root: classes.label },
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button fullWidth variant={"contained"} color={"secondary"} onClick={handleClose}>
                        Call Me!
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RequestACall;
